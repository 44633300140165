import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HandymanIcon from '@mui/icons-material/Handyman';
import coinIcon from '@mui/icons-material/MonetizationOn';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { Role } from 'src/models/roles';
import routeNames from 'src/utils/routeNames';

export interface MenuItem {
    link?: string;
    icon?: any;
    badge?: string;
    badgeTooltip?: string;

    items?: MenuItem[];
    name: string;
    roles: Role[];
}

export interface MenuItems {
    items: MenuItem[];
    heading: string;
    roles: Role[];
}
const menuItems: MenuItems[] = [
    {
        heading: 'Management',
        items: [
            {
                name: 'Users',
                icon: AssignmentIndTwoToneIcon,
                link: routeNames.users.home,
                items: [
                    {
                        name: 'Users',
                        link: routeNames.users.home,
                        roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
                    },
                    {
                        name: 'Generated Users',
                        link: routeNames.users.generated,
                        roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
                    },
                ],
                roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
            },
        ],
        roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
    },
    {
        heading: 'Management',
        items: [
            {
                name: 'Management',
                icon: HandymanIcon,
                items: [
                    {
                        name: 'Stickers',
                        link: routeNames.management.stickers,
                        roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
                    },
                    {
                        name: 'Sound Effects',
                        link: routeNames.management.soundEffects,
                        roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
                    },
                    {
                        name: 'Music',
                        link: routeNames.management.music,
                        roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
                    },
                    {
                        name: 'Settings',
                        link: routeNames.management.settings,
                        roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
                    },
                    {
                        name: 'Usernames Checklist',
                        link: routeNames.management.usernames,
                        roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
                    },
                    {
                        name: 'Games',
                        link: routeNames.management.games,
                        roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
                    },
                    {
                        name: 'Email Templates',
                        link: routeNames.management.emails.index,
                        roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
                    },
                ],
                roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
            },
        ],
        roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
    },
    {
        heading: 'Ads',
        items: [
            {
                name: 'Ads',
                icon: AnalyticsTwoToneIcon,
                link: routeNames.ads.campaigns.home,
                items: [
                    {
                        name: 'Campaigns',
                        link: routeNames.ads.campaigns.home,
                        roles: [],
                    },
                    {
                        name: 'Campaigns Requests',
                        link: routeNames.ads.campaigns.requests,
                        roles: [Role.ADMIN, Role.MODERATOR],
                    },
                ],
                roles: [],
            },
        ],
        roles: [],
    },
    {
        heading: 'Feedback',
        items: [
            {
                name: 'Feedback',
                icon: FeedbackIcon,
                link: routeNames.feedback.index,
                items: [
                    {
                        name: 'Feedback',
                        link: routeNames.feedback.index,
                        roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
                    },
                ],
                roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
            },
        ],
        roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
    },
    {
        heading: 'Posts',
        items: [
            {
                name: 'Posts',
                icon: SmartDisplayIcon,
                link: routeNames.posts.index,
                items: [
                    {
                        name: 'Posts',
                        link: routeNames.posts.index,
                        roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
                    },
                    {
                        name: 'Reported Posts',
                        link: routeNames.reports.home,
                        roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
                    },
                ],
                roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
            },
        ],
        roles: [Role.ADMIN, Role.MODERATOR, Role.SUPER_ADMIN],
    },
    {
        heading: 'Coins',
        items: [
            {
                name: 'Coins',
                icon: coinIcon,
                link: routeNames.management.coins,
                items: [
                    {
                        name: 'Coins',
                        link: routeNames.management.coins,
                        roles: [],
                    },
                ],
                roles: [],
            },
        ],
        roles: [],
    },
];

export default menuItems;
